import React from "react";
import { graphql } from "gatsby";
import SEO from "../components/SEO";
import {
  PageSection,
  PageSectionArticle,
  PageSectionWrapper,
  TextEditor,
} from "../components/common";
import { toHTML } from "../utils/helpers";
import './theboattrip.css'

const TheBoatTripPageTemplate = ({ title, theboattripData }) => {
  const { heading, body } = theboattripData;
  return (
    <>
      <SEO title={title} />
      <PageSectionWrapper bgColor="var(--creamWhite)">
        <PageSection bgColor="var(--creamWhite)">
          <PageSectionArticle>
            <h1>{heading}</h1>
            <TextEditor
              style={{ maxWidth: "900px" }}
              dangerouslySetInnerHTML={{ __html: toHTML(body) }}
            />
          </PageSectionArticle>
        </PageSection>
      </PageSectionWrapper>
    </>
  );
};

const TheBoatTripPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <TheBoatTripPageTemplate
      title={frontmatter.title}
      theboattripData={frontmatter.theboattripData}
    />
  );
};

export default TheBoatTripPage;

export const pageQuery = graphql`
  query TheBoatTripPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        theboattripData {
          heading
          body
        }
      }
    }
  }
`;
